@font-face {
font-family: '__zapfHumanistRegular_161f3c';
src: url(/_next/static/media/b0c3e971fec396ef-s.p.woff2) format('woff2');
font-display: swap;
}@font-face {font-family: '__zapfHumanistRegular_Fallback_161f3c';src: local("Arial");ascent-override: 101.42%;descent-override: 28.96%;line-gap-override: 0.00%;size-adjust: 94.95%
}.__className_161f3c {font-family: '__zapfHumanistRegular_161f3c', '__zapfHumanistRegular_Fallback_161f3c', system-ui, arial
}.__variable_161f3c {--font-humanist: '__zapfHumanistRegular_161f3c', '__zapfHumanistRegular_Fallback_161f3c', system-ui, arial
}

@font-face {
font-family: '__gtSectraMedium_a70a47';
src: url(/_next/static/media/1769a54244037a47-s.p.woff2) format('woff2');
font-display: swap;
}@font-face {font-family: '__gtSectraMedium_Fallback_a70a47';src: local("Arial");ascent-override: 99.39%;descent-override: 26.37%;line-gap-override: 0.00%;size-adjust: 98.60%
}.__className_a70a47 {font-family: '__gtSectraMedium_a70a47', '__gtSectraMedium_Fallback_a70a47', system-ui, arial
}.__variable_a70a47 {--gt-sectra-medium: '__gtSectraMedium_a70a47', '__gtSectraMedium_Fallback_a70a47', system-ui, arial
}

@font-face {
font-family: '__interBold_928a61';
src: url(/_next/static/media/1e3e4a3108c86bc3-s.p.ttf) format('truetype');
font-display: swap;
}@font-face {font-family: '__interBold_Fallback_928a61';src: local("Arial");ascent-override: 87.00%;descent-override: 21.69%;line-gap-override: 0.00%;size-adjust: 111.35%
}.__className_928a61 {font-family: '__interBold_928a61', '__interBold_Fallback_928a61', system-ui, arial
}.__variable_928a61 {--inter-bold: '__interBold_928a61', '__interBold_Fallback_928a61', system-ui, arial
}

.glider-contain {
    width: 100%;
    margin: 0 auto;
    position: relative;
}

.glider {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0px;
    margin-bottom: 0px;
    display: flex;
    cursor: grab;
    cursor: -webkit-grab;
    overflow-x: scroll;
    white-space: nowrap;
}

.glider:active {
    cursor: grabbing;
    cursor: -webkit-grabbing;
}

.glider {
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    transform: translateZ(0);
}

.glider-track {
    transform: translateZ(0);
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    z-index: 1;
}

.glider.draggable {
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
    cursor: grab;
}

.glider.draggable .glider-slide img {
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
    pointer-events: none;
}

.glider.drag {
    cursor: grabbing;
}

.glider-slide {
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
    justify-content: center;
    align-content: center;
    width: 100%;
    flex: 1 !important;
}

.custom-glider-btn:not(.disabled) .glider-svg-button:hover {
    fill: #45d4d5;
}

.glider-slide img {
    max-width: 100%;
}

.glider::-webkit-scrollbar {
    opacity: 0;
    height: 0;
}

.custom-glider-btn,
.glider-prev,
.glider-next {
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
    position: absolute;
    outline: none;
    background: none;
    padding: 0;
    z-index: 2;
    font-size: 40px;
    text-decoration: none;
    left: 23px;
    border: 0;
    top: 50%;
    cursor: pointer;
    opacity: 1;
    line-height: 1;
    transition: opacity .5s cubic-bezier(.17, .67, .83, .67),
        color .5s cubic-bezier(.17, .67, .83, .67);
}


.custom-glider-btn:hover,
.custom-glider-btn:focus,
.glider-prev:hover,
.glider-next:hover,
.glider-prev:focus,
.glider-next:focus {
    color: #a89cc8;
}

.glider-next {
    right: 23px;
    left: auto;
}

.custom-glider-btn.disabled,
.glider-next.disabled,
.glider-prev.disabled {
    opacity: .17;
    color: #ffffff00;
    cursor: default;
}

.glider-slide {
    min-width: 150px;
}

.glider-hide {
    opacity: 0;
}

.glider-dots {
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    padding: 0;
}

.glider-dot {
    border: 0;
    padding: 0;
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
    outline: none;
    display: block;
    cursor: pointer;
    color: #ccc;
    border-radius: 999px;
    background: #ccc;
    width: 12px;
    height: 12px;
    margin: 7px;
}

.glider-dot:hover,
.glider-dot:focus,
.glider-dot.active {
    background: #a89cc8;
}

@media(max-width: 36em) {
    .glider::-webkit-scrollbar {
        opacity: 1;
        -webkit-appearance: none;
        width: 7px;
        height: 3px;
    }

    .glider::-webkit-scrollbar-thumb {
        opacity: 1;
        border-radius: 99px;
        background-color: rgba(156, 156, 156, 0.25);
        box-shadow: 0 0 1px rgba(255, 255, 255, .25);
    }
}

@media screen and (max-width: 767px) {

}
[class*="post_stars"] {
    filter: drop-shadow( 1px 1px 0px rgba(0, 0, 0, 0.4));
}

.slide {
    flex: 0 0 100%;
    scroll-snap-align: start;
}

.carousel-div {
    min-height: calc(100vh / 5);
    min-width: 21vw;
}

.custom-glider-btn {
    position: absolute;
    z-index: 2;
    display: block;
    height: 30px;
    width: 30px;
}

.mention-img {
    filter: drop-shadow(0.6px 0.5px 0px #0a4f55bf);
    filter: grayscale(1);
}

.mention-img:hover {
    cursor: pointer;
    transform: scale3d(1.1, 1.1, 1.1);
    filter: contrast(-200%) hue-rotate(80deg);
}

@media screen and (max-width: 767px) {
    .carousel-div {
        min-width: 55vw;
    }

    .zoomable-img {
        min-width: 55vw;
    }
}
