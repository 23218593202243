.slide {
    @apply flex-[0_0_100%] snap-start;
}

.carousel-div {
    min-height: calc(100vh / 5);
    min-width: 21vw;
}

.custom-glider-btn {
    @apply block absolute w-[30px] h-[30px] z-[2];
}

.mention-img {
    filter: drop-shadow(0.6px 0.5px 0px #0a4f55bf);
    filter: grayscale(1);
}

.mention-img:hover {
    cursor: pointer;
    transform: scale3d(1.1, 1.1, 1.1);
    filter: contrast(-200%) hue-rotate(80deg);
}

@media screen and (max-width: 767px) {
    .carousel-div {
        min-width: 55vw;
    }

    .zoomable-img {
        min-width: 55vw;
    }
}